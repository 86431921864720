<template>
  <div class="container text-center">
    <div class="email-form-container">
      <h3>{{ $t("accountManagement.title") }}</h3>
      <div class="my-4">
        {{ $t("accountManagement.description") }}
      </div>
      <form @submit.prevent="sendEmailWithCustomerPortalLink">
        <b-form-group>
          <b-form-input
            type="email"
            v-model="email"
            :placeholder="$t('accountManagement.emailPlaceholder')"
          ></b-form-input>
          <b-alert class="mt-4" :show="actionResult.message.length > 1">{{
            actionResult.message
          }}</b-alert>
          <b-button type="submit" class="w-100 my-4">{{
            $t("accountManagement.requestEmail")
          }}</b-button>
        </b-form-group>
      </form>
    </div>
  </div>
</template>

<script>
import GPS from "@/utils/api";

export default {
  data() {
    return {
      email: null,
      actionResult: {
        error: false,
        message: true,
      },
    };
  },
  computed: {},
  async mounted() {},
  watch: {},
  methods: {
    async sendEmailWithCustomerPortalLink() {
      if (this.email.length < 3 || !/^.+@.+\..+/.test(this.email)) {
        this.actionResult = {
          error: true,
          message: this.$t("accountManagement.noValidEmail"),
        };
        return;
      }
      try {
        const { data: result } = await GPS.sendEmailWithCustomerPortalLink(
          this.email
        );
        if (result.error) {
          this.actionResult = {
            error: true,
              message: this.$t("accountManagement.noEmail"),
          };
        } else {
          this.actionResult = {
            error: false,
              message: this.$t("accountManagement.emailSent"),
          };
        }
      } catch (err) {
        this.actionResult = {
          error: true,
          message: this.$t("accountManagement.noEmail"),
        };
      }
    },
  },
};
</script>

<style scoped>
.email-form-container {
  max-width: 480px;
  margin: 2rem auto;
}
</style>
