<template>
  <div class="home">
    <div
      class="d-flex flex-wrap justify-content-between align-content-center w-100"
    >
      <div class="info-section">
        <product-info :selectedProduct="selectedProduct" />
      </div>
      <div class="new-account-card my-4">
        <h3>{{ $t("home.title") }}</h3>

        <Form
          :objectType="objectType"
          :selectFields="selectFields"
          class="my-2"
        />
      </div>
    </div>
    <div class="footer">
      <small class="contact-footer">
        <router-link to="/imprint-privacy-policy"
          >Impressum und Datenschutz</router-link
        >
        |
        <router-link to="/terms-and-conditions">AGB</router-link>
        |
        <b-link href="https://tegdi.com" target="_blank">TEGDI GmbH</b-link>
      </small>
    </div>
    <small>
      <router-link to="/accounts/management" class="action-footer"
        >Abonnement verwalten</router-link
      >
    </small>
  </div>
</template>

<script>
// @ is an alias to /src
import GPS from "@/utils/api";
import Form from "@/components/Form.vue";
import ProductInfo from "../components/ProductInfo.vue";

export default {
  name: "Home",
  components: { ProductInfo, Form },
  data() {
    return {
      products: [],
      form: {},
      objectType: "user",
      selectedProduct: {},

      selectFields: [
        {
          field: "baseproductid",
          type: "baseproduct",
          optionValueField: "baseProductId",
          optionTextField: "name",
        },
      ],
    };
  },
  computed: {
    planQuery() {
      return this.$route.query.plan;
    },
  },
  async mounted() {
    await this.getProducts();
    this.setSelectedProduct();
  },
  watch: {
    planQuery() {
      this.setSelectedProduct();
    },
  },
  methods: {
    async getProducts() {
      const { data: products } = await GPS.getProducts();
      this.products = products;
    },
    setSelectedProduct() {
      if (this.planQuery) {
        this.selectedProduct = this.products.find(
          (prod) => prod.baseProductId === this.planQuery
        );
      } else {
        this.$router.push({
          query: {
            plan: JSON.parse(JSON.stringify(this.products[0].baseProductId)),
          },
        });
      }
    },
  },
};
</script>

<style scoped>
h3 {
  font-size: 16px;
  font-weight: 600;
}

.new-account-card {
  width: 100%;
  margin-top: 2rem;
}

.action-footer {
  display: block;
  text-decoration: underline;
}

@media (min-width: 640px) {
  .new-account-card {
    width: 40vw;
    margin-top: 0;
  }

  .info-section {
    min-height: 81vh;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .info-section::-webkit-scrollbar {
    display: none;
  }

  .action-footer {
    position: absolute;
    right: 10vw;
    bottom: 16px;
  }
}
</style>
