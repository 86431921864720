import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import AccountError from "../views/AccountError.vue";
import AccountSuccess from "../views/AccountSuccess.vue";
import AccountManagement from "../views/AccountManagement.vue";
import ImprintPrivacyPolicy from "../views/ImprintPrivacyPolicy.vue";
import TermsAndConditions from "../views/TermsAndConditions.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/accounts/new",
    name: "Home",
    component: Home,
  },
  {
    path: "/accounts/success",
    name: "AccountSuccess",
    component: AccountSuccess,
  },
  {
    path: "/accounts/error",
    name: "AccountError",
    component: AccountError,
  },
  {
    path: "/imprint-privacy-policy",
    name: "ImprintPrivacyPolicy",
    component: ImprintPrivacyPolicy,
  },
  {
    path: "/terms-and-conditions",
    name: "TermsAndConditions",
    component: TermsAndConditions,
  },
  {
    path: "/accounts/management",
    name: "AccountManagement",
    component: AccountManagement,
  },
  {
    path: "*",
    redirect: "/accounts/new",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
