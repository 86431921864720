<template>
  <div>
    <div class="d-flex justify-content-end mb-2">
      <b-button
        size="sm"
        variant="outline-secondary"
        @click="addObjToList(property)"
        >{{ $t(`form.${property.title}`) }} hinzufügen</b-button
      >
    </div>
    <div
      v-for="(line, index) in formValue"
      :key="index"
      class="py-4 border-bottom border-top"
    >
      <div v-for="prop in property.items.properties" :key="prop.title">
        <form-field
          :property="prop"
          :objectType="objectType"
          :selectOptions="selectOptions"
          :updateSelectCounter="updateSelectCounter"
          :formData="formValue[index][prop.title]"
          @update="
            formValue[index][prop.title] = $event;
            $emit('update', formValue);
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormField from "./FormField.vue";

export default {
  components: {
    FormField,
  },
  props: {
    objectType: { type: String, default: "user" },
    selectFields: { type: Array, default: null },
    property: { type: Object, default: null },
    formData: { type: Array, default: null },
    selectOptions: { type: Object, default: null },
    updateSelectCounter: { type: Number, default: 0 },
  },
  data() {
    return {
      formValue: [],
    };
  },
  computed: {},
  mounted() {
    this.formValue = this.formData;
  },
  watch: {
    formData: function (newValue) {
      this.formValue = newValue;
    },
  },

  methods: {
    addObjToList(prop) {
      const attributes = Object.keys(prop.items.properties);
      const emptyObj = {};
      attributes.forEach((attr) => (emptyObj[attr] = ""));
      this.formValue.push(emptyObj);
    },
  },
};
</script>

<style scoped></style>
