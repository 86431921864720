import axios from "axios";

const frontendCredentials = {
  auth: {
    username: process.env.VUE_APP_FRONTENDUSER,
    password: process.env.VUE_APP_FRONTENDPASSWORD,
  },
};

const baseUrl = process.env.VUE_APP_API_URL;

const GPS = {
  getStatus: () => {
    const url = `${baseUrl}/status`;
    return axios.get(url);
  },

  persistUser: (user) => {
    const url = `${baseUrl}/users/`;
    return axios.put(url, user, frontendCredentials);
  },

  startTransaction: (transaction) => {
    const url = `${baseUrl}/transactions/start`;
    return axios.post(url, transaction, frontendCredentials);
  },

  deleteUser: (userId) => {
    const url = `${baseUrl}/users/${userId}`;
    return axios.delete(url);
  },

  getProducts: () => {
    const url = `${baseUrl}/baseproducts/pricing`;
    return axios.get(url);
  },

  // new
  persistItem: (item) => {
    const url = `${baseUrl}/${item.type}s/`;
    return axios.put(url, item.data, frontendCredentials);
  },
  retrieveItems: (item) => {
    const url = `${baseUrl}/${item}s/`;
    return axios.get(url, frontendCredentials);
  },
  deleteItem: (item) => {
    const url = `${baseUrl}/${item.type}s/${item.id}`;
    return axios.delete(url);
  },
  checkSubDomain: (name) => {
    const url = `${baseUrl}/subdomain/${encodeURIComponent(name)}`;
    return axios.get(url, frontendCredentials);
  },
  updateTransactionStatus: (data) => {
    const url = `${baseUrl}/transactions/notify`;
    return axios.post(url, data);
  },

  sendEmailWithCustomerPortalLink: (email) => {
    const url = `${baseUrl}/users/customer-portal`;
    return axios.post(url, { email: email }, frontendCredentials);
  },
};

export default GPS;
