<template>
  <b-card no-body class="shadow new-account-card">
    <form v-if="schema" class="form p-4" @submit.prevent="persistItem">
      <div v-for="property in schema.properties" :key="property.title">
        <div
          v-if="property.type === 'object'"
          class="form-group my-3"
          :title="$t(`form.${property.title}.title`)"
        >
          <form-object
            :property="property"
            :objectType="objectType"
            :selectOptions="selectOptions"
            :updateSelectCounter="updateSelectCounter"
            :formData="formData[property.title]"
            @update="formData[property.title] = $event"
            @stateChange="isValid[property.title] = $event"
          />
        </div>
      </div>
      <b-button type="submit" class="float-right">
        {{ $t(`account.submit`) }}
      </b-button>
    </form>
  </b-card>
</template>

<script>
import data from "@/utils/jsonSchema";
import GPS from "@/utils/api";
import { v4 as uuidv4, validate as uuidValidate } from "uuid";
import { loadStripe } from "@stripe/stripe-js";

import FormObject from "./FormObject.vue";

export default {
  components: {
    FormObject
  },
  props: {
    objectType: { type: String, default: "user" },
    selectFields: { type: Array, default: null }
  },
  data() {
    return {
      tabIndex: 0,
      spk: process.env.VUE_APP_STRIPEPUBKEY,
      stripe: null,
      schema: null,
      formData: {},
      actionResult: {
        error: false,
        message: ""
      },
      actionType: "create",
      selectOptions: {},
      updateSelectCounter: 0,
      updateArrayCounter: 0,
      isValid: {},
      systemLocale: "de"
    };
  },
  computed: {
    validationState() {
      return Object.entries(this.isValid);
    },
    objectPropertiesKeys() {
      if (this.schema.properties) {
        return Object.keys(this.schema.properties);
      } else {
        return [];
      }
    },
    objectPropertiesCount() {
      return this.objectPropertiesKeys.length;
    },
    alertType() {
      return this.actionResult.error ? "danger" : "primary";
    },
    objectName() {
      return this.$t(`${this.objectType}.instance`);
    },

    toastHeader() {
      return this.actionResult.error
        ? ` ${this.$t("account.instance")} ${this.$t(
            "account.create"
          )} fehlgeschlagen`
        : `${this.$t("account.instance")}  erfolgreich ${this.$t(
            "account.created"
          )} `;
    }
  },
  async mounted() {
    this.stripe = await loadStripe(this.spk);
    this.selectOptions.country = data.countries.sort((countryA, countryB) => {
      if (countryA.text < countryB.text) return -1;
      if (countryA.text > countryB.text) return 1;
      return 0;
    });

    this.schema = data[this.objectType];

    //if selectFields are specified in view generate select options
    if (this.selectFields && this.selectFields.length > 0) {
      this.selectFields.forEach(item => {
        this.setSelectOptions(item);
      });
    }
    // if property type is array set formData attribute with empty array
    const values = Object.values(this.schema.properties);
    values.forEach(prop => {
      if (prop.type === "array") {
        this.formData[prop.title] = [];
      }
    });
    this.getSessionStorage();
    const transactionId = sessionStorage.getItem("transactionId") || undefined;
    if (transactionId) this.updateTransactionStatus("canceled");
    this.systemLocale = this.getLang();
  },
  methods: {
    makeToast() {
      this.$bvToast.toast(this.actionResult.message, {
        title: this.toastHeader,
        variant: this.alertType,
        solid: true
      });
    },
    async setSelectOptions(object) {
      const { data: items } = await GPS.getProducts();
      this.selectOptions[object.field] = await items.map(item => {
        return {
          value: item[object.optionValueField],
          text: item[object.optionTextField]
        };
      });
      this.updateSelectCounter++;
    },
    validateProductComponents() {
      if (this.formData.product.productComponents) {
        return this.formData.product.productComponents.filter(prodComp =>
          uuidValidate(prodComp.componentId)
        );
      } else return [];
    },
    setProductUserData() {
      const item = {
        type: "product",
        data: {
          id: this.formData.product.id || uuidv4(),
          customer: {
            role: "CUSTOMER",
            username: this.formData.accountData.email,
            rawPassword: this.formData.accountData.rawPassword || "",
            userdata: JSON.stringify({
              subdomain: this.formData.product.subDomain,
              country: this.formData.accountData.country || "DE",
              taxId: this.formData.accountData.taxId || "",
              customerAddress:
                JSON.stringify(this.formData.billingAddress) || "",
              locale: this.systemLocale
            })
          },
          baseproductid:
            this.formData.product.baseproductid || this.$route.query.plan,
          productComponents: this.validateProductComponents(),
          customerDomain: this.formData.product.subDomain
        }
      };
      if (![undefined, ""].includes(this.formData.accountData.id))
        item.data.customer.id = this.formData.accountData.id;
      return item;
    },
    setSessionStorage() {
      sessionStorage.setItem("username", this.formData.accountData.email || "");
      sessionStorage.setItem(
        "subDomain",
        this.formData.product.subDomain || ""
      );
      sessionStorage.setItem(
        "baseproductid",
        this.formData.product.baseproductid || ""
      );
      sessionStorage.setItem(
        "country",
        this.formData.accountData.country || "DE"
      );
      sessionStorage.setItem("taxId", this.formData.accountData.taxId || "");
    },
    setIdInSessionStorage(id, type) {
      sessionStorage.setItem(type, id || undefined);
    },
    getSessionStorage() {
      this.formData = {
        accountData: {
          email: sessionStorage.getItem("username") || "",
          id: sessionStorage.getItem("userId") || "",
          country: sessionStorage.getItem("country") || "",
          taxId: sessionStorage.getItem("taxId") || ""
        },
        product: {
          id: sessionStorage.getItem("productId") || undefined,
          subDomain: sessionStorage.getItem("subDomain") || "",
          baseproductid:
            sessionStorage.getItem("baseproductid") || this.$route.query.plan
        }
      };
    },

    setTransactionData(product) {
      return {
        product: JSON.stringify(product),
        userId: product.customerid,
        type: "SUBSCRIPTION",
        meta: JSON.stringify({
          returnUrl: window.location.href,
          successUrl:
            window.location.origin +
            "/accounts/success?session_id={CHECKOUT_SESSION_ID}"
        })
      };
    },
    getLang() {
      if (navigator.languages != undefined)
        return navigator.languages[0].slice(0, 2);
      return navigator.language.slice(0, 2);
    },
    validateForm() {
      const validationStates = Object.entries(this.isValid)
        .map(entry => Object.values(entry[1]))
        .flat();
      return !validationStates.includes(false);
    },
    async updateTransactionStatus(state) {
      const statusEvent = {
        gpsEventType: "updateStatus",
        data: {
          object: {
            status: state,
            transactionId: sessionStorage.getItem("transactionId")
          }
        }
      };
      await GPS.updateTransactionStatus(statusEvent);
    },
    async startTransaction(product) {
      let sessionId = sessionStorage.getItem("stripeSessionId") || undefined;
      if (!sessionId) {
        const transaction = this.setTransactionData(product);
        const { data: transactionWithSession } = await GPS.startTransaction(
          transaction
        );
        sessionId = JSON.parse(transactionWithSession.meta).stripeSessionId;
        this.setIdInSessionStorage(sessionId, "stripeSessionId");
        this.setIdInSessionStorage(transactionWithSession.id, "transactionId");
      } else {
        await this.updateTransactionStatus("created");
      }
      this.stripe.redirectToCheckout({
        sessionId: sessionId
      });
      this.$emit("update");
    },

    async persistItem() {
      const isValid = this.validateForm();
      if (!isValid) {
        this.actionResult = {
          error: true,
          message: this.$t("account.validationError")
        };
        this.makeToast();
        return;
      }
      const item =
        this.objectType === "user"
          ? this.setProductUserData()
          : {
              type: this.objectType,
              data: this.formData
            };
      this.setSessionStorage();
      try {
        const { data: product } = await GPS.persistItem(item);
        this.actionResult = {
          error: false,
          message: this.$t("account.success")
        };
        this.setIdInSessionStorage(await product.customerid, "userId");
        this.setIdInSessionStorage(await product.id, "productId");
        this.startTransaction(await product);
      } catch (error) {
        const message = error?.response?.data.startsWith(
          "Error: customer missing"
        )
          ? this.$t("account.errorUserDuplicate")
          : this.$t("account.error");
        this.actionResult = {
          error: true,
          message: message
        };
        this.makeToast();
      }
    }
  }
};
</script>
