import Vue from "vue";
import App from "./App.vue";
import { BootstrapVue } from "bootstrap-vue";
import { IconsPlugin } from "bootstrap-vue";
import i18n from "./i18n";

// import store from "./utils/store";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
// Vue.use(store);

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  render: (h) => h(App),
}).$mount("#app");

import "./styles/global.scss";
import router from "./router";
