<template>
  <b-form-group
    v-if="property.type !== 'boolean'"
    label-cols="12"
    label-cols-lg="4"
    :label="title"
    :label-for="property.title"
    :description="property.description || ''"
    label-size="sm"
    :invalid-feedback="validationError"
    :state="state"
    class="m-0 my-lg-3"
  >
    <b-form-select
      :key="updateSelectCounter"
      v-if="property.enum"
      v-model="formValue"
      :options="
        property.enum.length === 0
          ? selectOptions[property.title]
          : property.enum
      "
      @change="updateValue()"
      :state="state"
    ></b-form-select>

    <b-input-group v-else :append="property.append">
      <template #append v-if="property.append">
        <b-input-group-text
          ><small>{{ property.append }}</small></b-input-group-text
        >
      </template>
      <b-input
        :type="getType(property)"
        :id="property.title"
        :disabled="property.disabled"
        v-model="formValue"
        :class="cssClass"
        :placeholder="property.default"
        @change="updateValue()"
        :state="state"
      />
    </b-input-group>
  </b-form-group>
  <b-form-checkbox
    class="mt-2"
    v-else-if="property.type === 'boolean'"
    v-model="formValue"
    name="check-button"
    @change="updateValue()"
    ><small>{{ title }}</small></b-form-checkbox
  >
</template>

<script>
import GPS from "@/utils/api";

export default {
  name: "FormField",
  props: {
    property: { type: Object, default: null },
    objectType: { type: String, default: "user" },
    formData: null,
    selectOptions: { type: Object, default: null },
    updateSelectCounter: { type: Number, default: 0 },
    required: { type: Boolean, default: true },
  },
  data() {
    return {
      formValue: undefined,
      cssClass: "",
      isValid: true,
      validationError: "",
    };
  },
  computed: {
    title() {
      const label = this.$t(`form.${this.property.title}`);
      return this.required ? label + " *" : label;
    },
    state() {
      if (
        this.required &&
        (this.formValue === undefined || this.formValue === "")
      )
        return false;
      if (!this.property.pattern || this.formValue === undefined) return null;
      const regExp = new RegExp(this.property.pattern);
      const isMatching = regExp.test(this.formValue);
      if (isMatching && this.isValid) {
        return true;
      } else {
        return false;
      }
    },
    planQuery() {
      return this.$route.query.plan;
    },
  },
  async mounted() {
    this.formValue = this.formData;
    if (this.property.title === "country") {
      this.$nextTick(function () {
        this.formValue = this.selectOptions.country.find(
          (c) => c.value === "DE"
        ).value;
        this.$emit("update", this.formValue);
        this.$emit("stateChange", this.state);
      });
    }

    if (this.property.title === "subDomain" && this.formValue?.length >= 3) {
      await this.checkSubDomain();
    }
    this.updateSelectedProduct();

    this.$emit("update", this.formValue);
    this.$emit("stateChange", this.state);
  },
  watch: {
    formData: function (newValue) {
      this.formValue = newValue;
      this.$emit("stateChange", this.state);
    },
    planQuery() {
      this.updateSelectedProduct();
    },
  },
  methods: {
    updateSelectedProduct() {
      if (this.$route.query.plan && this.property.title === "baseproductid") {
        this.formValue = this.planQuery;
        this.$emit("update", this.formValue);
        this.$emit("stateChange", this.state);
      }
    },
    getType(prop) {
      if (prop.type === "string" && prop.format) return prop.format;
      else if (prop.type === "string" && !prop.format) return "text";
      else if (prop.type === "number") return prop.type;
    },
    async updateValue() {
      if (
        this.property.title === "baseproductid" &&
        this.$route.query.plan !== this.formValue
      ) {
        this.$router.push({
          query: { plan: this.formValue },
        });
      }
      this.$emit("update", this.formValue);
      if (this.property.title === "subDomain" && this.formValue.length >= 3)
        await this.checkSubDomain();
      this.$emit("stateChange", this.state);
    },
    async checkSubDomain() {
      const { data: isValid } = await GPS.checkSubDomain(this.formValue);
      this.isValid = isValid;
      this.validationError = this.isValid ? "" : this.$t("form.subDomainTaken");
    },
  },
};
</script>

<style scoped></style>
