<template>
  <div class="imprint-privacy-policy">
    <section class="container">
      <article id="imprint" class="my-4">
        <h4>Impressum</h4>
        <div class="center">
          <h6>WebAttach ist eine Software der TEGDI GmbH.</h6>

          <p>
            TEGDI Technische Entwicklungsgesellschaft für digitale Innovationen
            mbH
          </p>

          <p>
            Wilhelmstraße 14<br />
            72074 Tübingen
          </p>

          <p>Email: info@tegdi.com<br /></p>

          <p>
            Registergericht: Amtsgericht Stuttgart<br />
            Handelsregistereintrag: HRB 774840<br />
            UstID: DE332813789 <br />
            Geschäftsführung: Tim Kühnl, Malte Lösch
          </p>
        </div>
      </article>
      <!-- Datenschutz -->
      <article id="data-privacy-policy" class="my-4">
        <h4>Datenschutzerklärung</h4>
        <h5>Einleitung</h5>
        <p>
          Mit der folgenden Datenschutzerklärung möchten wir Sie darüber
          aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend
          auch kurz als "Daten“ bezeichnet) wir zu welchen Zwecken und in
          welchem Umfang verarbeiten. Die Datenschutzerklärung gilt für alle von
          uns durchgeführten Verarbeitungen personenbezogener Daten, sowohl im
          Rahmen der Erbringung unserer Leistungen als auch insbesondere auf
          unseren Webseiten, in mobilen Applikationen sowie innerhalb externer
          Onlinepräsenzen, wie z.B. unserer Social-Media-Profile (nachfolgend
          zusammenfassend bezeichnet als "Onlineangebot“).
        </p>
        <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
        <p>Stand: 21. Juli 2021</p>
        <h5>Inhaltsübersicht</h5>
        <ul class="index">
          <li><a class="index-link" href="#m14">Einleitung</a></li>
          <li><a class="index-link" href="#m3">Verantwortlicher</a></li>
          <li>
            <a class="index-link" href="#mOverview"
              >Übersicht der Verarbeitungen</a
            >
          </li>
          <li>
            <a class="index-link" href="#m13">Maßgebliche Rechtsgrundlagen</a>
          </li>
          <li><a class="index-link" href="#m27">Sicherheitsmaßnahmen</a></li>
          <li>
            <a class="index-link" href="#m24"
              >Datenverarbeitung in Drittländern</a
            >
          </li>
          <li><a class="index-link" href="#m12">Löschung von Daten</a></li>
          <li><a class="index-link" href="#m134">Einsatz von Cookies</a></li>
          <li>
            <a class="index-link" href="#m317">Geschäftliche Leistungen</a>
          </li>
          <li><a class="index-link" href="#m326">Zahlungsverfahren</a></li>
          <li>
            <a class="index-link" href="#m225"
              >Bereitstellung des Onlineangebotes und Webhosting</a
            >
          </li>
          <li>
            <a class="index-link" href="#m328"
              >Plugins und eingebettete Funktionen sowie Inhalte</a
            >
          </li>
          <li>
            <a class="index-link" href="#m723"
              >Management, Organisation und Hilfswerkzeuge</a
            >
          </li>
          <li>
            <a class="index-link" href="#m15"
              >Änderung und Aktualisierung der Datenschutzerklärung</a
            >
          </li>
          <li>
            <a class="index-link" href="#m10"
              >Rechte der betroffenen Personen</a
            >
          </li>
          <li><a class="index-link" href="#m42">Begriffsdefinitionen</a></li>
        </ul>
        <h5 id="m3" class="priv-anchor">Verantwortlicher</h5>
        <p>
          Tim Kühnl <br />TEGDI GmbH<br />Wilhelmstrasse 14, 72074 Tübingen,
          Deutschland
        </p>
        <p>
          E-Mail-Adresse:
          <a href="mailto:datenschutz@tegdi.com">datenschutz@tegdi.com</a>.
        </p>
        <p>
          Impressum:
          <a href="https://tegdi.com/impressum.html" target="_blank"
            >https://tegdi.com/impressum.html</a
          >.
        </p>
        <h5 id="mOverview" class="priv-anchor">Übersicht der Verarbeitungen</h5>
        <p>
          Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und
          die Zwecke ihrer Verarbeitung zusammen und verweist auf die
          betroffenen Personen.
        </p>
        <h6>Arten der verarbeiteten Daten</h6>
        <ul>
          <li>Bestandsdaten (z.B. Namen, Adressen).</li>
          <li>Inhaltsdaten (z.B. Eingaben in Onlineformularen).</li>
          <li>Kontaktdaten (z.B. E-Mail, Telefonnummern).</li>
          <li>
            Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
          </li>
          <li>
            Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
            Zugriffszeiten).
          </li>
          <li>
            Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie).
          </li>
          <li>
            Zahlungsdaten (z.B. Bankverbindungen, Rechnungen, Zahlungshistorie).
          </li>
        </ul>
        <h6>Kategorien betroffener Personen</h6>
        <ul>
          <li>Geschäfts- und Vertragspartner.</li>
          <li>Interessenten.</li>
          <li>Kommunikationspartner.</li>
          <li>Kunden.</li>
          <li>Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
        </ul>
        <h6>Zwecke der Verarbeitung</h6>
        <ul>
          <li>
            Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
          </li>
          <li>Büro- und Organisationsverfahren.</li>
          <li>Direktmarketing (z.B. per E-Mail oder postalisch).</li>
          <li>Kontaktanfragen und Kommunikation.</li>
          <li>Sicherheitsmaßnahmen.</li>
          <li>Erbringung vertraglicher Leistungen und Kundenservice.</li>
          <li>Verwaltung und Beantwortung von Anfragen.</li>
        </ul>
        <h6 id="m13" class="priv-anchor">Maßgebliche Rechtsgrundlagen</h6>
        <p>
          Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der
          DSGVO, auf deren Basis wir personenbezogene Daten verarbeiten. Bitte
          nehmen Sie zur Kenntnis, dass neben den Regelungen der DSGVO nationale
          Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland gelten
          können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen
          maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung
          mit.
        </p>
        <ul>
          <li>
            <strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO)</strong> -
            Die betroffene Person hat ihre Einwilligung in die Verarbeitung der
            sie betreffenden personenbezogenen Daten für einen spezifischen
            Zweck oder mehrere bestimmte Zwecke gegeben.
          </li>
          <li>
            <strong
              >Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S.
              1 lit. b. DSGVO)</strong
            >
            - Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen
            Vertragspartei die betroffene Person ist, oder zur Durchführung
            vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der
            betroffenen Person erfolgen.
          </li>
          <li>
            <strong
              >Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c.
              DSGVO)</strong
            >
            - Die Verarbeitung ist zur Erfüllung einer rechtlichen Verpflichtung
            erforderlich, der der Verantwortliche unterliegt.
          </li>
          <li>
            <strong
              >Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO)</strong
            >
            - Die Verarbeitung ist zur Wahrung der berechtigten Interessen des
            Verantwortlichen oder eines Dritten erforderlich, sofern nicht die
            Interessen oder Grundrechte und Grundfreiheiten der betroffenen
            Person, die den Schutz personenbezogener Daten erfordern,
            überwiegen.
          </li>
        </ul>
        <p>
          <strong>Nationale Datenschutzregelungen in Deutschland</strong>:
          Zusätzlich zu den Datenschutzregelungen der
          Datenschutz-Grundverordnung gelten nationale Regelungen zum
          Datenschutz in Deutschland. Hierzu gehört insbesondere das Gesetz zum
          Schutz vor Missbrauch personenbezogener Daten bei der
          Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält
          insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf
          Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer
          Kategorien personenbezogener Daten, zur Verarbeitung für andere Zwecke
          und zur Übermittlung sowie automatisierten Entscheidungsfindung im
          Einzelfall einschließlich Profiling. Des Weiteren regelt es die
          Datenverarbeitung für Zwecke des Beschäftigungsverhältnisses (§ 26
          BDSG), insbesondere im Hinblick auf die Begründung, Durchführung oder
          Beendigung von Beschäftigungsverhältnissen sowie die Einwilligung von
          Beschäftigten. Ferner können Landesdatenschutzgesetze der einzelnen
          Bundesländer zur Anwendung gelangen.
        </p>
        <h5 id="m27" class="priv-anchor">Sicherheitsmaßnahmen</h5>
        <p>
          Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter
          Berücksichtigung des Stands der Technik, der Implementierungskosten
          und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung
          sowie der unterschiedlichen Eintrittswahrscheinlichkeiten und des
          Ausmaßes der Bedrohung der Rechte und Freiheiten natürlicher Personen
          geeignete technische und organisatorische Maßnahmen, um ein dem Risiko
          angemessenes Schutzniveau zu gewährleisten.
        </p>
        <p>
          Zu den Maßnahmen gehören insbesondere die Sicherung der
          Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch
          Kontrolle des physischen und elektronischen Zugangs zu den Daten als
          auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der
          Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir
          Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten,
          die Löschung von Daten und Reaktionen auf die Gefährdung der Daten
          gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener
          Daten bereits bei der Entwicklung bzw. Auswahl von Hardware, Software
          sowie Verfahren entsprechend dem Prinzip des Datenschutzes, durch
          Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.
        </p>
        <p>
          <strong>SSL-Verschlüsselung (https)</strong>: Um Ihre via unserem
          Online-Angebot übermittelten Daten zu schützen, nutzen wir eine
          SSL-Verschlüsselung. Sie erkennen derart verschlüsselte Verbindungen
          an dem Präfix https:// in der Adresszeile Ihres Browsers.
        </p>
        <h5 id="m24" class="priv-anchor">Datenverarbeitung in Drittländern</h5>
        <p>
          Sofern wir Daten in einem Drittland (d.h., außerhalb der Europäischen
          Union (EU), des Europäischen Wirtschaftsraums (EWR)) verarbeiten oder
          die Verarbeitung im Rahmen der Inanspruchnahme von Diensten Dritter
          oder der Offenlegung bzw. Übermittlung von Daten an andere Personen,
          Stellen oder Unternehmen stattfindet, erfolgt dies nur im Einklang mit
          den gesetzlichen Vorgaben.
        </p>
        <p>
          Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich oder
          gesetzlich erforderlicher Übermittlung verarbeiten oder lassen wir die
          Daten nur in Drittländern mit einem anerkannten Datenschutzniveau,
          vertraglichen Verpflichtung durch sogenannte Standardschutzklauseln
          der EU-Kommission, beim Vorliegen von Zertifizierungen oder
          verbindlicher internen Datenschutzvorschriften verarbeiten (Art. 44
          bis 49 DSGVO, Informationsseite der EU-Kommission:
          <a
            href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de"
            target="_blank"
            >https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de</a
          >).
        </p>
        <h5 id="m12" class="priv-anchor">Löschung von Daten</h5>
        <p>
          Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen
          Vorgaben gelöscht, sobald deren zur Verarbeitung erlaubten
          Einwilligungen widerrufen werden oder sonstige Erlaubnisse entfallen
          (z.B. wenn der Zweck der Verarbeitung dieser Daten entfallen ist oder
          sie für den Zweck nicht erforderlich sind).
        </p>
        <p>
          Sofern die Daten nicht gelöscht werden, weil sie für andere und
          gesetzlich zulässige Zwecke erforderlich sind, wird deren Verarbeitung
          auf diese Zwecke beschränkt. D.h., die Daten werden gesperrt und nicht
          für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus
          handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen oder
          deren Speicherung zur Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen
          oder juristischen Person erforderlich ist.
        </p>
        <p>
          Unsere Datenschutzhinweise können ferner weitere Angaben zu der
          Aufbewahrung und Löschung von Daten beinhalten, die für die jeweiligen
          Verarbeitungen vorrangig gelten.
        </p>
        <h5 id="m134" class="priv-anchor">Einsatz von Cookies</h5>
        <p>
          Cookies sind Textdateien, die Daten von besuchten Websites oder
          Domains enthalten und von einem Browser auf dem Computer des Benutzers
          gespeichert werden. Ein Cookie dient in erster Linie dazu, die
          Informationen über einen Benutzer während oder nach seinem Besuch
          innerhalb eines Onlineangebotes zu speichern. Zu den gespeicherten
          Angaben können z.B. die Spracheinstellungen auf einer Webseite, der
          Loginstatus, ein Warenkorb oder die Stelle, an der ein Video geschaut
          wurde, gehören. Zu dem Begriff der Cookies zählen wir ferner andere
          Technologien, die die gleichen Funktionen wie Cookies erfüllen (z.B.
          wenn Angaben der Nutzer anhand pseudonymer Onlinekennzeichnungen
          gespeichert werden, auch als "Nutzer-IDs" bezeichnet)
        </p>
        <p>
          <strong
            >Die folgenden Cookie-Typen und Funktionen werden
            unterschieden:</strong
          >
        </p>
        <ul>
          <li>
            <strong
              >Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):</strong
            >&nbsp;Temporäre Cookies werden spätestens gelöscht, nachdem ein
            Nutzer ein Online-Angebot verlassen und seinen Browser geschlossen
            hat.
          </li>
          <li>
            <strong>Permanente Cookies:</strong>&nbsp;Permanente Cookies bleiben
            auch nach dem Schließen des Browsers gespeichert. So kann
            beispielsweise der Login-Status gespeichert oder bevorzugte Inhalte
            direkt angezeigt werden, wenn der Nutzer eine Website erneut
            besucht. Ebenso können die Interessen von Nutzern, die zur
            Reichweitenmessung oder zu Marketingzwecken verwendet werden, in
            einem solchen Cookie gespeichert werden.
          </li>
          <li>
            <strong>First-Party-Cookies:</strong>&nbsp;First-Party-Cookies
            werden von uns selbst gesetzt.
          </li>
          <li>
            <strong>Third-Party-Cookies (auch: Drittanbieter-Cookies)</strong>:
            Drittanbieter-Cookies werden hauptsächlich von Werbetreibenden (sog.
            Dritten) verwendet, um Benutzerinformationen zu verarbeiten.
          </li>
          <li>
            <strong
              >Notwendige (auch: essentielle oder unbedingt erforderliche)
              Cookies:</strong
            >
            Cookies können zum einen für den Betrieb einer Webseite unbedingt
            erforderlich sein (z.B. um Logins oder andere Nutzereingaben zu
            speichern oder aus Gründen der Sicherheit).
          </li>
          <li>
            <strong>Statistik-, Marketing- und Personalisierungs-Cookies</strong
            >: Ferner werden Cookies im Regelfall auch im Rahmen der
            Reichweitenmessung eingesetzt sowie dann, wenn die Interessen eines
            Nutzers oder sein Verhalten (z.B. Betrachten bestimmter Inhalte,
            Nutzen von Funktionen etc.) auf einzelnen Webseiten in einem
            Nutzerprofil gespeichert werden. Solche Profile dienen dazu, den
            Nutzern z.B. Inhalte anzuzeigen, die ihren potentiellen Interessen
            entsprechen. Dieses Verfahren wird auch als "Tracking", d.h.,
            Nachverfolgung der potentiellen Interessen der Nutzer bezeichnet.
            Soweit wir Cookies oder "Tracking"-Technologien einsetzen,
            informieren wir Sie gesondert in unserer Datenschutzerklärung oder
            im Rahmen der Einholung einer Einwilligung.
          </li>
        </ul>
        <p>
          <strong>Hinweise zu Rechtsgrundlagen: </strong> Auf welcher
          Rechtsgrundlage wir Ihre personenbezogenen Daten mit Hilfe von Cookies
          verarbeiten, hängt davon ab, ob wir Sie um eine Einwilligung bitten.
          Falls dies zutrifft und Sie in die Nutzung von Cookies einwilligen,
          ist die Rechtsgrundlage der Verarbeitung Ihrer Daten die erklärte
          Einwilligung. Andernfalls werden die mithilfe von Cookies
          verarbeiteten Daten auf Grundlage unserer berechtigten Interessen
          (z.B. an einem betriebswirtschaftlichen Betrieb unseres
          Onlineangebotes und dessen Verbesserung) verarbeitet oder, wenn der
          Einsatz von Cookies erforderlich ist, um unsere vertraglichen
          Verpflichtungen zu erfüllen.
        </p>
        <p>
          <strong>Speicherdauer: </strong>Sofern wir Ihnen keine expliziten
          Angaben zur Speicherdauer von permanenten Cookies mitteilen (z. B. im
          Rahmen eines sog. Cookie-Opt-Ins), gehen Sie bitte davon aus, dass die
          Speicherdauer bis zu zwei Jahre betragen kann.
        </p>
        <p>
          <strong
            >Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out):
          </strong>
          Abhängig davon, ob die Verarbeitung auf Grundlage einer Einwilligung
          oder gesetzlichen Erlaubnis erfolgt, haben Sie jederzeit die
          Möglichkeit, eine erteilte Einwilligung zu widerrufen oder der
          Verarbeitung Ihrer Daten durch Cookie-Technologien zu widersprechen
          (zusammenfassend als "Opt-Out" bezeichnet). Sie können Ihren
          Widerspruch zunächst mittels der Einstellungen Ihres Browsers
          erklären, z.B., indem Sie die Nutzung von Cookies deaktivieren (wobei
          hierdurch auch die Funktionsfähigkeit unseres Onlineangebotes
          eingeschränkt werden kann). Ein Widerspruch gegen den Einsatz von
          Cookies zu Zwecken des Onlinemarketings kann auch mittels einer
          Vielzahl von Diensten, vor allem im Fall des Trackings, über die
          Webseiten
          <a href="https://optout.aboutads.info" target="_blank"
            >https://optout.aboutads.info</a
          >
          und
          <a href="https://www.youronlinechoices.com/" target="_blank"
            >https://www.youronlinechoices.com/</a
          >
          erklärt werden. Daneben können Sie weitere Widerspruchshinweise im
          Rahmen der Angaben zu den eingesetzten Dienstleistern und Cookies
          erhalten.
        </p>
        <p>
          <strong
            >Verarbeitung von Cookie-Daten auf Grundlage einer
            Einwilligung</strong
          >: Wir setzen ein Verfahren zum Cookie-Einwilligungs-Management ein,
          in dessen Rahmen die Einwilligungen der Nutzer in den Einsatz von
          Cookies, bzw. der im Rahmen des
          Cookie-Einwilligungs-Management-Verfahrens genannten Verarbeitungen
          und Anbieter eingeholt sowie von den Nutzern verwaltet und widerrufen
          werden können. Hierbei wird die Einwilligungserklärung gespeichert, um
          deren Abfrage nicht erneut wiederholen zu müssen und die Einwilligung
          entsprechend der gesetzlichen Verpflichtung nachweisen zu können. Die
          Speicherung kann serverseitig und/oder in einem Cookie (sogenanntes
          Opt-In-Cookie, bzw. mithilfe vergleichbarer Technologien) erfolgen, um
          die Einwilligung einem Nutzer, bzw. dessen Gerät zuordnen zu können.
          Vorbehaltlich individueller Angaben zu den Anbietern von
          Cookie-Management-Diensten, gelten die folgenden Hinweise: Die Dauer
          der Speicherung der Einwilligung kann bis zu zwei Jahren betragen.
          Hierbei wird ein pseudonymer Nutzer-Identifikator gebildet und mit dem
          Zeitpunkt der Einwilligung, Angaben zur Reichweite der Einwilligung
          (z. B. welche Kategorien von Cookies und/oder Diensteanbieter) sowie
          dem Browser, System und verwendeten Endgerät gespeichert.
        </p>
        <ul class="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B.
            besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten),
            Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Nutzer (z.B.
            Webseitenbesucher, Nutzer von Onlinediensten).
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1
            lit. a. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f.
            DSGVO).
          </li>
        </ul>
        <h5 id="m317" class="priv-anchor">Geschäftliche Leistungen</h5>
        <p>
          Wir verarbeiten Daten unserer Vertrags- und Geschäftspartner, z.B.
          Kunden und Interessenten (zusammenfassend bezeichnet als
          "Vertragspartner") im Rahmen von vertraglichen und vergleichbaren
          Rechtsverhältnissen sowie damit verbundenen Maßnahmen und im Rahmen
          der Kommunikation mit den Vertragspartnern (oder vorvertraglich),
          z.B., um Anfragen zu beantworten.
        </p>
        <p>
          Diese Daten verarbeiten wir zur Erfüllung unserer vertraglichen
          Pflichten, zur Sicherung unserer Rechte und zu Zwecken der mit diesen
          Angaben einhergehenden Verwaltungsaufgaben sowie der unternehmerischen
          Organisation. Die Daten der Vertragspartner geben wir im Rahmen des
          geltenden Rechts nur insoweit an Dritte weiter, als dies zu den
          vorgenannten Zwecken oder zur Erfüllung gesetzlicher Pflichten
          erforderlich ist oder mit Einwilligung der betroffenen Personen
          erfolgt (z.B. an beteiligte Telekommunikations-, Transport- und
          sonstige Hilfsdienste sowie Subunternehmer, Banken, Steuer- und
          Rechtsberater, Zahlungsdienstleister oder Steuerbehörden). Über
          weitere Verarbeitungsformen, z.B. zu Zwecken des Marketings, werden
          die Vertragspartner im Rahmen dieser Datenschutzerklärung informiert.
        </p>
        <p>
          Welche Daten für die vorgenannten Zwecke erforderlich sind, teilen wir
          den Vertragspartnern vor oder im Rahmen der Datenerhebung, z.B. in
          Onlineformularen, durch besondere Kennzeichnung (z.B. Farben) bzw.
          Symbole (z.B. Sternchen o.ä.), oder persönlich mit.
        </p>
        <p>
          Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und
          vergleichbarer Pflichten, d.h., grundsätzlich nach Ablauf von 4
          Jahren, es sei denn, dass die Daten in einem Kundenkonto gespeichert
          werden, z.B., solange sie aus gesetzlichen Gründen der Archivierung
          aufbewahrt werden müssen (z.B. für Steuerzwecke im Regelfall 10
          Jahre). Daten, die uns im Rahmen eines Auftrags durch den
          Vertragspartner offengelegt wurden, löschen wir entsprechend den
          Vorgaben des Auftrags, grundsätzlich nach Ende des Auftrags.
        </p>
        <p>
          Soweit wir zur Erbringung unserer Leistungen Drittanbieter oder
          Plattformen einsetzen, gelten im Verhältnis zwischen den Nutzern und
          den Anbietern die Geschäftsbedingungen und Datenschutzhinweise der
          jeweiligen Drittanbieter oder Plattformen.
        </p>
        <p>
          <strong>Kundenkonto</strong>: Vertragspartner können innerhalb unseres
          Onlineangebotes ein Konto anlegen (z.B. Kunden- bzw. Nutzerkonto, kurz
          "Kundenkonto"). Falls die Registrierung eines Kundenkontos
          erforderlich ist, werden Vertragspartner hierauf ebenso hingewiesen
          wie auf die für die Registrierung erforderlichen Angaben. Die
          Kundenkonten sind nicht öffentlich und können von Suchmaschinen nicht
          indexiert werden. Im Rahmen der Registrierung sowie anschließender
          Anmeldungen und Nutzungen des Kundenkontos speichern wir die
          IP-Adressen der Kunden nebst den Zugriffszeitpunkten, um die
          Registrierung nachweisen und etwaigem Missbrauch des Kundenkontos
          vorbeugen zu können.
        </p>
        <p>
          Wenn Kunden ihr Kundenkonto gekündigt haben, werden die das
          Kundenkonto betreffenden Daten gelöscht, vorbehaltlich, deren
          Aufbewahrung ist aus gesetzlichen Gründen erforderlich. Es obliegt den
          Kunden, ihre Daten bei erfolgter Kündigung des Kundenkontos zu
          sichern.
        </p>
        <ul class="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
            Adressen), Zahlungsdaten (z.B. Bankverbindungen, Rechnungen,
            Zahlungshistorie), Kontaktdaten (z.B. E-Mail, Telefonnummern),
            Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie),
            Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
            Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
            Geräte-Informationen, IP-Adressen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Interessenten, Geschäfts- und
            Vertragspartner, Kunden.
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
            Leistungen und Kundenservice, Kontaktanfragen und Kommunikation,
            Büro- und Organisationsverfahren, Verwaltung und Beantwortung von
            Anfragen, Sicherheitsmaßnahmen.
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
            vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO),
            Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c. DSGVO),
            Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
          </li>
        </ul>
        <h5 id="m326" class="priv-anchor">Zahlungsverfahren</h5>
        <p>
          Im Rahmen von Vertrags- und sonstigen Rechtsbeziehungen, aufgrund
          gesetzlicher Pflichten oder sonst auf Grundlage unserer berechtigten
          Interessen bieten wir den betroffenen Personen effiziente und sichere
          Zahlungsmöglichkeiten an und setzen hierzu neben Banken und
          Kreditinstituten weitere Dienstleister ein (zusammenfassend
          "Zahlungsdienstleister").
        </p>
        <p>
          Zu den durch die Zahlungsdienstleister verarbeiteten Daten gehören
          Bestandsdaten, wie z.B. der Name und die Adresse, Bankdaten, wie z.B.
          Kontonummern oder Kreditkartennummern, Passwörter, TANs und Prüfsummen
          sowie die Vertrags-, Summen- und empfängerbezogenen Angaben. Die
          Angaben sind erforderlich, um die Transaktionen durchzuführen. Die
          eingegebenen Daten werden jedoch nur durch die Zahlungsdienstleister
          verarbeitet und bei diesen gespeichert. D.h., wir erhalten keine
          konto- oder kreditkartenbezogenen Informationen, sondern lediglich
          Informationen mit Bestätigung oder Negativbeauskunftung der Zahlung.
          Unter Umständen werden die Daten seitens der Zahlungsdienstleister an
          Wirtschaftsauskunfteien übermittelt. Diese Übermittlung bezweckt die
          Identitäts- und Bonitätsprüfung. Hierzu verweisen wir auf die AGB und
          die Datenschutzhinweise der Zahlungsdienstleister.
        </p>
        <p>
          Für die Zahlungsgeschäfte gelten die Geschäftsbedingungen und die
          Datenschutzhinweise der jeweiligen Zahlungsdienstleister, welche
          innerhalb der jeweiligen Webseiten bzw. Transaktionsapplikationen
          abrufbar sind. Wir verweisen auf diese ebenfalls zwecks weiterer
          Informationen und Geltendmachung von Widerrufs-, Auskunfts- und
          anderen Betroffenenrechten.
        </p>
        <ul class="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
            Adressen), Zahlungsdaten (z.B. Bankverbindungen, Rechnungen,
            Zahlungshistorie), Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit,
            Kundenkategorie), Nutzungsdaten (z.B. besuchte Webseiten, Interesse
            an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
            Geräte-Informationen, IP-Adressen).
          </li>
          <li><strong>Betroffene Personen:</strong> Kunden, Interessenten.</li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
            Leistungen und Kundenservice.
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
            vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO),
            Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
          </li>
        </ul>
        <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
        <ul class="m-elements">
          <li>
            <strong>Stripe:</strong> Zahlungsdienstleistungen; Dienstanbieter:
            Stripe, Inc., 510 Townsend Street, San Francisco, CA 94103, USA;
            Website:
            <a href="https://stripe.com" target="_blank">https://stripe.com</a>;
            Datenschutzerklärung:
            <a href="https://stripe.com/de/privacy" target="_blank"
              >https://stripe.com/de/privacy</a
            >.
          </li>
        </ul>
        <h5 id="m225" class="priv-anchor">
          Bereitstellung des Onlineangebotes und Webhosting
        </h5>
        <p>
          Um unser Onlineangebot sicher und effizient bereitstellen zu können,
          nehmen wir die Leistungen von einem oder mehreren Webhosting-Anbietern
          in Anspruch, von deren Servern (bzw. von ihnen verwalteten Servern)
          das Onlineangebot abgerufen werden kann. Zu diesen Zwecken können wir
          Infrastruktur- und Plattformdienstleistungen, Rechenkapazität,
          Speicherplatz und Datenbankdienste sowie Sicherheitsleistungen und
          technische Wartungsleistungen in Anspruch nehmen.
        </p>
        <p>
          Zu den im Rahmen der Bereitstellung des Hostingangebotes verarbeiteten
          Daten können alle die Nutzer unseres Onlineangebotes betreffenden
          Angaben gehören, die im Rahmen der Nutzung und der Kommunikation
          anfallen. Hierzu gehören regelmäßig die IP-Adresse, die notwendig ist,
          um die Inhalte von Onlineangeboten an Browser ausliefern zu können,
          und alle innerhalb unseres Onlineangebotes oder von Webseiten
          getätigten Eingaben.
        </p>
        <p>
          <strong>Erhebung von Zugriffsdaten und Logfiles</strong>: Wir selbst
          (bzw. unser Webhostinganbieter) erheben Daten zu jedem Zugriff auf den
          Server (sogenannte Serverlogfiles). Zu den Serverlogfiles können die
          Adresse und Name der abgerufenen Webseiten und Dateien, Datum und
          Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über
          erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des
          Nutzers, Referrer URL (die zuvor besuchte Seite) und im Regelfall
          IP-Adressen und der anfragende Provider gehören.
        </p>
        <p>
          Die Serverlogfiles können zum einen zu Zwecken der Sicherheit
          eingesetzt werden, z.B., um eine Überlastung der Server zu vermeiden
          (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten
          DDoS-Attacken) und zum anderen, um die Auslastung der Server und ihre
          Stabilität sicherzustellen.
        </p>
        <ul class="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Inhaltsdaten (z.B.
            Eingaben in Onlineformularen), Nutzungsdaten (z.B. besuchte
            Webseiten, Interesse an Inhalten, Zugriffszeiten),
            Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Nutzer (z.B.
            Webseitenbesucher, Nutzer von Onlinediensten).
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
            Onlineangebotes und Nutzerfreundlichkeit.
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
            Abs. 1 S. 1 lit. f. DSGVO).
          </li>
        </ul>
        <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
        <ul class="m-elements">
          <li>
            <strong>Hetzner:</strong> Leistungen auf dem Gebiet der
            Bereitstellung von informationstechnischer Infrastruktur und
            verbundenen Dienstleistungen (z.B. Speicherplatz und/oder
            Rechenkapazitäten); Dienstanbieter: Hetzner Online GmbH,
            Industriestr. 25, 91710 Gunzenhausen, Deutschland; Website:
            <a href="https://www.hetzner.com" target="_blank"
              >https://www.hetzner.com</a
            >; Datenschutzerklärung:
            <a
              href="https://www.hetzner.com/de/rechtliches/datenschutz"
              target="_blank"
              >https://www.hetzner.com/de/rechtliches/datenschutz</a
            >.
          </li>
        </ul>
        <h5 id="m328" class="priv-anchor">
          Plugins und eingebettete Funktionen sowie Inhalte
        </h5>
        <p>
          Wir binden in unser Onlineangebot Funktions- und Inhaltselemente ein,
          die von den Servern ihrer jeweiligen Anbieter (nachfolgend bezeichnet
          als "Drittanbieter”) bezogen werden. Dabei kann es sich zum Beispiel
          um Grafiken, Videos oder Stadtpläne handeln (nachfolgend einheitlich
          bezeichnet als "Inhalte”).
        </p>
        <p>
          Die Einbindung setzt immer voraus, dass die Drittanbieter dieser
          Inhalte die IP-Adresse der Nutzer verarbeiten, da sie ohne die
          IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die
          IP-Adresse ist damit für die Darstellung dieser Inhalte oder
          Funktionen erforderlich. Wir bemühen uns, nur solche Inhalte zu
          verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur
          Auslieferung der Inhalte verwenden. Drittanbieter können ferner
          sogenannte Pixel-Tags (unsichtbare Grafiken, auch als "Web Beacons"
          bezeichnet) für statistische oder Marketingzwecke verwenden. Durch die
          "Pixel-Tags" können Informationen, wie der Besucherverkehr auf den
          Seiten dieser Webseite, ausgewertet werden. Die pseudonymen
          Informationen können ferner in Cookies auf dem Gerät der Nutzer
          gespeichert werden und unter anderem technische Informationen zum
          Browser und zum Betriebssystem, zu verweisenden Webseiten, zur
          Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebotes
          enthalten als auch mit solchen Informationen aus anderen Quellen
          verbunden werden.
        </p>
        <p>
          <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer
          um deren Einwilligung in den Einsatz der Drittanbieter bitten, ist die
          Rechtsgrundlage der Verarbeitung von Daten die Einwilligung. Ansonsten
          werden die Daten der Nutzer auf Grundlage unserer berechtigten
          Interessen (d.h. Interesse an effizienten, wirtschaftlichen und
          empfängerfreundlichen Leistungen) verarbeitet. In diesem Zusammenhang
          möchten wir Sie auch auf die Informationen zur Verwendung von Cookies
          in dieser Datenschutzerklärung hinweisen.
        </p>
        <ul class="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B.
            besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten),
            Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Nutzer (z.B.
            Webseitenbesucher, Nutzer von Onlinediensten).
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
            Onlineangebotes und Nutzerfreundlichkeit, Erbringung vertraglicher
            Leistungen und Kundenservice.
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
            Abs. 1 S. 1 lit. f. DSGVO).
          </li>
        </ul>
        <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
        <ul class="m-elements">
          <li>
            <strong>Google Fonts:</strong> Wir binden die Schriftarten ("Google
            Fonts") des Anbieters Google ein, wobei die Daten der Nutzer allein
            zu Zwecken der Darstellung der Schriftarten im Browser der Nutzer
            verwendet werden. Die Einbindung erfolgt auf Grundlage unserer
            berechtigten Interessen an einer technisch sicheren, wartungsfreien
            und effizienten Nutzung von Schriftarten, deren einheitlicher
            Darstellung sowie unter Berücksichtigung möglicher lizenzrechtlicher
            Restriktionen für deren Einbindung. Dienstanbieter: Google Ireland
            Limited, Gordon House, Barrow Street, Dublin 4, Irland,
            Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain
            View, CA 94043, USA; Website:
            <a href="https://fonts.google.com/" target="_blank"
              >https://fonts.google.com/</a
            >; Datenschutzerklärung:
            <a href="https://policies.google.com/privacy" target="_blank"
              >https://policies.google.com/privacy</a
            >.
          </li>
        </ul>
        <h5 id="m723" class="priv-anchor">
          Management, Organisation und Hilfswerkzeuge
        </h5>
        <p>
          Wir setzen Dienstleistungen, Plattformen und Software anderer Anbieter
          (nachfolgend bezeichnet als "Drittanbieter”) zu Zwecken der
          Organisation, Verwaltung, Planung sowie Erbringung unserer Leistungen
          ein. Bei der Auswahl der Drittanbieter und ihrer Leistungen beachten
          wir die gesetzlichen Vorgaben.
        </p>
        <p>
          In diesem Rahmen können personenbezogenen Daten verarbeitet und auf
          den Servern der Drittanbieter gespeichert werden. Hiervon können
          diverse Daten betroffen sein, die wir entsprechend dieser
          Datenschutzerklärung verarbeiten. Zu diesen Daten können insbesondere
          Stammdaten und Kontaktdaten der Nutzer, Daten zu Vorgängen, Verträgen,
          sonstigen Prozessen und deren Inhalte gehören.
        </p>
        <p>
          Sofern Nutzer im Rahmen der Kommunikation, von Geschäfts- oder anderen
          Beziehungen mit uns auf die Drittanbieter bzw. deren Software oder
          Plattformen verwiesen werden, können die Drittanbieter Nutzungsdaten
          und Metadaten zu Sicherheitszwecken, zur Serviceoptimierung oder zu
          Marketingzwecken verarbeiten. Wir bitten daher darum, die
          Datenschutzhinweise der jeweiligen Drittanbieter zu beachten.
        </p>
        <p>
          <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer
          um deren Einwilligung in den Einsatz der Drittanbieter bitten, ist die
          Rechtsgrundlage der Verarbeitung von Daten die Einwilligung. Ferner
          kann deren Einsatz ein Bestandteil unserer (vor)vertraglichen
          Leistungen sein, sofern der Einsatz der Drittanbieter in diesem Rahmen
          vereinbart wurde. Ansonsten werden die Daten der Nutzer auf Grundlage
          unserer berechtigten Interessen (d.h. Interesse an effizienten,
          wirtschaftlichen und empfängerfreundlichen Leistungen) verarbeitet. In
          diesem Zusammenhang möchten wir Sie auch auf die Informationen zur
          Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.
        </p>
        <ul class="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
            Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten
            (z.B. Eingaben in Onlineformularen), Nutzungsdaten (z.B. besuchte
            Webseiten, Interesse an Inhalten, Zugriffszeiten),
            Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Kommunikationspartner, Nutzer
            (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1
            lit. a. DSGVO), Vertragserfüllung und vorvertragliche Anfragen (Art.
            6 Abs. 1 S. 1 lit. b. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1
            S. 1 lit. f. DSGVO).
          </li>
        </ul>
        <h5 id="m15" class="priv-anchor">
          Änderung und Aktualisierung der Datenschutzerklärung
        </h5>
        <p>
          Wir bitten Sie, sich regelmäßig über den Inhalt unserer
          Datenschutzerklärung zu informieren. Wir passen die
          Datenschutzerklärung an, sobald die Änderungen der von uns
          durchgeführten Datenverarbeitungen dies erforderlich machen. Wir
          informieren Sie, sobald durch die Änderungen eine Mitwirkungshandlung
          Ihrerseits (z.B. Einwilligung) oder eine sonstige individuelle
          Benachrichtigung erforderlich wird.
        </p>
        <p>
          Sofern wir in dieser Datenschutzerklärung Adressen und
          Kontaktinformationen von Unternehmen und Organisationen angeben,
          bitten wir zu beachten, dass die Adressen sich über die Zeit ändern
          können und bitten die Angaben vor Kontaktaufnahme zu prüfen.
        </p>
        <h5 id="m10" class="priv-anchor">Rechte der betroffenen Personen</h5>
        <p>
          Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die
          sich insbesondere aus Art. 15 bis 21 DSGVO ergeben:
        </p>
        <ul>
          <li>
            <strong
              >Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus
              Ihrer besonderen Situation ergeben, jederzeit gegen die
              Verarbeitung der Sie betreffenden personenbezogenen Daten, die
              aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt,
              Widerspruch einzulegen; dies gilt auch für ein auf diese
              Bestimmungen gestütztes Profiling. Werden die Sie betreffenden
              personenbezogenen Daten verarbeitet, um Direktwerbung zu
              betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die
              Verarbeitung der Sie betreffenden personenbezogenen Daten zum
              Zwecke derartiger Werbung einzulegen; dies gilt auch für das
              Profiling, soweit es mit solcher Direktwerbung in Verbindung
              steht.</strong
            >
          </li>
          <li>
            <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das
            Recht, erteilte Einwilligungen jederzeit zu widerrufen.
          </li>
          <li>
            <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine
            Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet
            werden und auf Auskunft über diese Daten sowie auf weitere
            Informationen und Kopie der Daten entsprechend den gesetzlichen
            Vorgaben.
          </li>
          <li>
            <strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den
            gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie
            betreffenden Daten oder die Berichtigung der Sie betreffenden
            unrichtigen Daten zu verlangen.
          </li>
          <li>
            <strong
              >Recht auf Löschung und Einschränkung der Verarbeitung:</strong
            >
            Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu
            verlangen, dass Sie betreffende Daten unverzüglich gelöscht werden,
            bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben eine
            Einschränkung der Verarbeitung der Daten zu verlangen.
          </li>
          <li>
            <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das
            Recht, Sie betreffende Daten, die Sie uns bereitgestellt haben, nach
            Maßgabe der gesetzlichen Vorgaben in einem strukturierten, gängigen
            und maschinenlesbaren Format zu erhalten oder deren Übermittlung an
            einen anderen Verantwortlichen zu fordern.
          </li>
          <li>
            <strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben
            unbeschadet eines anderweitigen verwaltungsrechtlichen oder
            gerichtlichen Rechtsbehelfs das Recht auf Beschwerde bei einer
            Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
            gewöhnlichen Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des
            mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass die
            Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die
            Vorgaben der DSGVO verstößt.
          </li>
        </ul>
        <h5 id="m42" class="priv-anchor">Begriffsdefinitionen</h5>
        <p>
          In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser
          Datenschutzerklärung verwendeten Begrifflichkeiten. Viele der Begriffe
          sind dem Gesetz entnommen und vor allem im Art. 4 DSGVO definiert. Die
          gesetzlichen Definitionen sind verbindlich. Die nachfolgenden
          Erläuterungen sollen dagegen vor allem dem Verständnis dienen. Die
          Begriffe sind alphabetisch sortiert.
        </p>
        <ul class="glossary">
          <li>
            <strong>Personenbezogene Daten:</strong> "Personenbezogene Daten“
            sind alle Informationen, die sich auf eine identifizierte oder
            identifizierbare natürliche Person (im Folgenden "betroffene
            Person“) beziehen; als identifizierbar wird eine natürliche Person
            angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung
            zu einer Kennung wie einem Namen, zu einer Kennnummer, zu
            Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem
            oder mehreren besonderen Merkmalen identifiziert werden kann, die
            Ausdruck der physischen, physiologischen, genetischen, psychischen,
            wirtschaftlichen, kulturellen oder sozialen Identität dieser
            natürlichen Person sind.
          </li>
          <li>
            <strong>Verantwortlicher:</strong> Als "Verantwortlicher“ wird die
            natürliche oder juristische Person, Behörde, Einrichtung oder andere
            Stelle, die allein oder gemeinsam mit anderen über die Zwecke und
            Mittel der Verarbeitung von personenbezogenen Daten entscheidet,
            bezeichnet.
          </li>
          <li>
            <strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit oder
            ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede
            solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten.
            Der Begriff reicht weit und umfasst praktisch jeden Umgang mit
            Daten, sei es das Erheben, das Auswerten, das Speichern, das
            Übermitteln oder das Löschen.
          </li>
        </ul>
      </article>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  data() {
    return {};
  },
};
</script>

<style scoped></style>
