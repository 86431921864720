<template>
  <b-navbar toggleable type="" variant="" class="">
    <div>
      <b-navbar-brand href="/"
        ><img src="/logo.svg" alt="icon" class="logo" />
        WebAttach</b-navbar-brand
      >
    </div>
  </b-navbar>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      servicesCollapsed: false,
      userCollapsed: false,
      productCollapsed: false,
      collapsed: false,
    };
  },
};
</script>

<style scoped>
.nav-link {
  text-align: right;
}

a.navbar-brand {
  color: var(--primary);
  margin-left: 1rem;
}

.username {
  color: var(--primary);
  margin-right: 1rem;
}

img {
  height: 30px;
}

.navbar-toggler {
  border: none;
}
</style>
