<template>
  <div class="imprint-privacy-policy">
    <section class="container">
      <article id="terms-and-conditions">
        <h4>Allgemeine Geschäftsbedingungen mit Kundeninformationen</h4>
        <p>Stand: 2. September 2021</p>
        <h6>Inhaltsübersicht</h6>
        <ul class="index">
          <li>
            <a class="index-link" href="#m2954">Geltungsbereich der AGB</a>
          </li>
          <li>
            <a class="index-link" href="#m3056"
              >Bestellvorgang und Vertragsschluss</a
            >
          </li>
          <li>
            <a class="index-link" href="#m3073"
              >Vertragstext und Vertragssprache</a
            >
          </li>
          <li>
            <a class="index-link" href="#m3027">
              Informationen zu Preisen und Versandkosten</a
            >
          </li>
          <li>
            <a class="index-link" href="#m3028"
              >Zahlungsarten und Zahlungsbedingungen</a
            >
          </li>
          <li><a class="index-link" href="#m3419">Eigentumsvorbehalt</a></li>
          <li>
            <a class="index-link" href="#m3042"
              >Lieferung, Warenverfügbarkeit</a
            >
          </li>
          <li><a class="index-link" href="#m3207">Abonnements</a></li>
          <li>
            <a class="index-link" href="#m3117">Gewährleistung und Haftung</a>
          </li>
          <li><a class="index-link" href="#m2981">Schlussbestimmungen</a></li>
          <li>
            <a class="index-link" href="#m3093"
              >Streitschlichtung und Verbraucherstreitbeilegung</a
            >
          </li>
        </ul>
        <h6 id="m2954">1. Geltungsbereich der AGB</h6>
        <ol type="a">
          <li>
            Für die Geschäftsbeziehung zwischen TEGDI Technische
            Entwicklungsgesellschaft für digitale Innovationen mbH (nachfolgend
            bezeichnet als „Verkäufer“) und dem nachfolgend als "Käufer"
            bezeichnetem Erwerber der Produkte und Leistungen des Verkäufers
            (nachfolgend bezeichnet als "Produkte" oder "Waren") gelten
            ausschließlich die nachfolgenden Allgemeinen Geschäftsbedingungen
            (nachfolgend bezeichnet als "AGB").
          </li>
          <li>
            Abweichende Bedingungen des Kunden werden nicht anerkannt, auch
            nicht wenn der Verkäufer seine Leistung widerspruchslos erbringt, es
            sei denn, der Verkäufer stimmt der Geltung der abweichenden
            Bedingungen des Kunden ausdrücklich zu.
          </li>
          <li>
            "Verbraucher" im Sinne dieser AGB ist jede natürliche Person, die
            ein Rechtsgeschäft zu Zwecken abschließt, der überwiegend weder
            ihrer gewerblichen noch ihrer selbstständigen beruflichen Tätigkeit
            zugerechnet werden können.
          </li>
          <li>
            "Unternehmer" im Sinne der AGB ist eine natürliche oder juristische
            Person oder eine rechtsfähige Personengesellschaft, die bei
            Abschluss eines Rechtsgeschäfts in Ausübung ihrer gewerblichen oder
            selbständigen beruflichen Tätigkeit handelt.
          </li>
          <li>
            Das Angebot des Verkäufers richtet sich ausschließlich an Kunden,
            die Unternehmer sind (auch bezeichnet als "Geschäftskunden" oder
            "B2B"). Mit der Bestellung oder Registrierung als Kunde, erklären
            die Kunden jeweils, dass sie ein Geschäftskunde und kein Verbraucher
            sind.
          </li>
        </ol>
        <h6 id="m3056">2. Bestellvorgang und Vertragsschluss</h6>
        <ol type="a">
          <li>
            Die Darstellung der Produkte im Shop, auf Webseiten und in digitalen
            gedruckten Broschüren, bzw. Katalogen oder vergleichbaren
            Produktpräsentationen des Verkäufers stellt kein rechtlich bindendes
            Angebot, sondern eine Aufforderung zur Abgabe einer Bestellung und
            damit des Angebotes des Kunden dar.
          </li>
          <li>
            Der Kunde kann aus dem im Sortiment des Verkäufers gegenüber dem
            Kunden angebotene Produkte auswählen und diese in einem so genannten
            Warenkorb sammeln. In der Auswahl innerhalb des Warenkorbes kann die
            Produktauswahl verändert, z.B. gelöscht werden. Andernfalls kann der
            Kunde den Abschluss des Bestellvorgangs einleiten.
          </li>
          <li>
            Der Verkäufer kann das Angebot des Kunden innerhalb der Annahmefrist
            annehmen. Die Annahmefrist beträgt fünf Tage. Die Annahmefrist
            beginnt mit dem Abschluss des Bestellvorgangs durch den Kunden im
            Shop (bzw. sofern benannt, mittels anderer Kommunikationswege) und
            endet mit Ablauf ihres letzten Tages. Der Verkäufer kann das Angebot
            des Kunden durch eine explizite Annahme des Vertrages, auch per
            E-Mail, annehmen. Die Annahme kann ferner durch den Versand der Ware
            und deren Zugang beim Kunden innerhalb der Annahmefrist erfolgen,
            als auch durch eine seitens des Verkäufers an den Kunden gerichtete
            Zahlungsaufforderung. Im Fall mehrerer Annahmevorgänge ist der
            früheste Annahmezeitpunkt maßgeblich. Nimmt der Verkäufer das
            Angebot des Kunden innerhalb der Annahmefrist nicht an, kommt kein
            Vertrag zustande und der Kunde wird nicht mehr an sein Angebot
            gebunden.
          </li>
          <li>
            Indem der Kunde auf die, den Bestellvorgang abschließende
            Schaltfläche klickt, gibt er ein verbindliches Angebot zum Erwerb
            der sich im Warenkorb befindlichen Produkte gegenüber dem Verkäufer
            ab.
          </li>
          <li>
            Die Kunden werden gebeten die Hinweise im Rahmen des
            Bestellprozesses sorgfältig zu lesen und zu beachten sowie bei
            Bedarf die vorhandenen Unterstützungsfunktionen ihrer Soft- und
            Hardware zu verwenden (z.B. Vergrößerungs- oder Vorlesefunktionen).
            Erforderliche Angaben werden vom Verkäufer als solche für den Kunden
            angemessen erkennbar gekennzeichnet (z.B. durch optische
            Hervorhebung und/oder Sternchen-Zeichen). Bis zum Absenden der
            Bestellung können die Kunden die Produktauswahl sowie ihre Eingaben
            jederzeit ändern und einsehen sowie zum Warenkorb zurückgehen oder
            den Bestellvorgang insgesamt abbrechen. Hierzu können die Kunden die
            ihnen bereitstehenden und üblichen Funktionen ihrer Software
            und/oder der Endgerätes verwenden (z.B. die Vor- und Zurücktasten
            des Browsers oder Tastatur-, Maus und Gestenfunktionen bei
            Mobilgeräten).
          </li>
          <li>
            Die Kunden sind verantwortlich, dass die von ihnen bereitgestellten
            Angaben zutreffend sind und etwaige Änderungen dem Verkäufer
            mitgeteilt werden, wenn sie zu dessen Vertragserfüllung erforderlich
            sind. Insbesondere haben die Kunden sicher zu stellen, dass die
            angegebenen E-Mail- und Lieferadressen zutreffend sind und etwaige
            Empfangsverhinderungen, die Kunden zu vertreten haben, entsprechend
            berücksichtigt werden (z.B. durch Kontrolle des Spamordners der
            verwendeten E-Mailsoftware).
          </li>
        </ol>
        <h6 id="m3073">3. Vertragstext und Vertragssprache</h6>
        <ol type="a">
          <li>
            Der Verkäufer speichert den Vertragstext und stellt ihn den Kunden
            in Textform (z.B. per E-Mail oder gedruckt mit der Lieferung der
            Bestellung) zur Verfügung. Der Kunde kann den Vertragstext vor der
            Abgabe der Bestellung an den Verkäufer ausdrucken, indem er im
            letzten Schritt der Bestellung die Druckfunktion seines Browsers
            oder die Speicherfunktion für Webseiten nutzt.
          </li>
          <li>
            Sofern Kunden ein Kundenkonto angelegt haben, können sie die in
            ihrem Profilbereich aufgegebenen Bestellungen einsehen.
          </li>
          <li>
            Die Vertragssprache ist Deutsch, Verträge können in dieser Sprache
            geschlossen werden.
          </li>
        </ol>
        <h6 id="m3027">4. Informationen zu Preisen und Versandkosten</h6>
        <ol type="a">
          <li>
            Alle Preisangaben verstehen sich, vorbehaltlich anderslautender
            Angaben, netto, zuzüglich der gültigen gesetzlichen Umsatzsteuer
            (MwSt.)
          </li>
          <li>
            Die zusätzlich zu dem Verkaufspreis anfallenden Liefer- und
            Versandgebühren werden dem Kunden bei der jeweiligen
            Produktbeschreibung und vor der Bestellabschluss mitgeteilt, bzw.
            verlinkt.
          </li>
        </ol>
        <h6 id="m3028">5. Zahlungsarten und Zahlungsbedingungen</h6>
        <ol type="a">
          <li>
            Zahlungen sind, sofern nicht anders vereinbart, ohne Abschlag,
            Skonti oder andere Nachlässe zu leisten.
          </li>
          <li>
            Beim Einsatz von Finanzinstituten und anderen
            Zahlungsdienstleistern, gelten im Hinblick auf die Bezahlung
            zusätzlich die Geschäftsbedingungen und Datenschutzhinweise der
            Zahlungsdienstleister. Kunden werden gebeten diese Regelungen und
            Hinweise als auch Informationen im Rahmen des Bezahlungsvorgangs zu
            beachten. Dies insbesondere, weil die Zurverfügungstellung von
            Zahlungsmethoden oder der Ablauf des Zahlungsverfahrens auch von den
            Vereinbarungen zwischen dem Kunden und Finanzinstituten und
            Zahlungsdienstleister abhängig sein können (z.B. vereinbarte
            Ausgabelimits, ortsbeschränkte Zahlungsmöglichkeiten,
            Verifizierungsverfahren, etc.).
          </li>
          <li>
            Der Kunde sorgt dafür, dass er die ihm obliegenden Voraussetzungen
            erfüllt, die zur erfolgreichen Bezahlung mittels der gewählten
            Zahlungsart erforderlich sind. Hierzu gehören insbesondere die
            hinreichende Deckung von Bank- und anderen Zahlungskonten,
            Registrierung, Legitimierungen und Autorisierung bei Bezahldiensten
            sowie die Bestätigung von Transaktionen.
          </li>
          <li>
            Sollte eine Zahlung aufgrund mangelnder Deckung des Kontos des
            Kunden, Angabe einer falschen Bankverbindung oder eines
            unberechtigten Widerspruchs des Kunden nicht durchgeführt oder
            zurück gebucht werden, dann trägt der Kunde die hierdurch
            entstandenen Gebühren, sofern er die fehlgeschlagene oder
            rückabgewickelte Buchung zu verantworten hat und im Fall einer
            SEPA-Überweisung rechtzeitig über die Überweisung informiert wurde
            (sogenannte "Pre-Notification").
          </li>
          <li>
            Den Kunden stehen die nachfolgend genannten Zahlungsarten zur
            Verfügung:
          </li>
          <li>
            SEPA Lastschriftverfahren - Mit der Abgabe der Bestellung erteilt
            der Kunde dem Verkäufer ein SEPA-Lastschriftmandat. Mit der
            Erteilung des SEPA-Lastschriftmandats wird der Verkäufer berechtigt,
            die Zahlungstransaktion einzuleiten, wodurch das Bankkonto des
            Kunden automatisch belastet wird. Der Kunde wird über das Datum der
            Belastung des Bankkontos informiert (bezeichnet als
            "Pre-Notification"). Die Pre-Notification ist nicht formgebunden und
            kann z.B. in Form einer Rechnung, Angaben in einer E-Mail, auf einer
            Webseite oder in AGB erfolgen. Die Frist für die Vorabkündigung des
            über das Datum der Belastung des Bankkontos beträgt 5 Tage
            (bezeichnet als "Pre-Notification-Frist"). Der Rechnungsbetrag wird
            nach Erteilung des Lastschriftmandats, jedoch nicht vor Ablauf der
            Pre-Notification-Frist fällig. Die Kontobelastung erfolgt vor
            Versand der Ware, jedoch nicht vor Ablauf der
            Pre-Notification-Frist.
          </li>
          <li>
            Kreditkartenzahlung - Mit Abgabe der Bestellung geben Kunden ihre
            Kreditkartendaten an. Die Kreditkarte des Kunden wird unmittelbar
            nach Abschluss der Bestellung und nach dessen Autorisierung als
            rechtmäßiger Karteninhaber belastet.
          </li>
        </ol>
        <h6 id="m3419">6. Eigentumsvorbehalt</h6>
        <p>
          Tritt der Verkäufer in Vorleistung, verbleiben die gelieferten
          Produkte bis zur vollständigen Bezahlung im Eigentum des Verkäufers.
        </p>
        <h6 id="m3042">7. Lieferung, Warenverfügbarkeit</h6>
        <ol type="a">
          <li>
            Die bestellten Waren werden an die angegebene Lieferanschrift
            geliefert, sofern keine abweichenden Vereinbarungen getroffen
            wurden.
          </li>
          <li>
            Sofern ein Zahlungsdienstleister eingesetzt wird, bei dem eine
            Lieferanschrift hinterlegt ist und durch die Verwendung des
            Zahlungsmittels durch den Kunden dem Verkäufer diese Lieferanschrift
            als für die bestellte Lieferung maßgeblich mitgeteilt wird, wird die
            Ware an die abweichende Lieferanschrift geliefert.
          </li>
          <li>
            Sollte die Zustellung der Ware durch Verschulden des Kunden trotz
            dreimaligem Auslieferversuchs scheitern, kann der Verkäufer vom
            Vertrag zurücktreten. Gegebenenfalls geleistete Zahlungen werden dem
            Kunden unverzüglich erstattet.
          </li>
          <li>
            Wenn das bestellte Produkt nicht verfügbar ist, weil der Verkäufer
            mit diesem Produkt von seinem Lieferanten ohne eigenes Verschulden
            nicht beliefert wird, kann der Verkäufer vom Vertrag zurücktreten.
            In diesem Fall wird der Verkäufer den Kunden unverzüglich
            informieren und ihm gegebenenfalls die Lieferung eines
            vergleichbaren Produktes vorschlagen. Wenn kein vergleichbares
            Produkt verfügbar ist oder der Kunde keine Lieferung eines
            vergleichbaren Produktes wünscht, wird der Verkäufer dem Kunden
            gegebenenfalls bereits erbrachte Gegenleistungen unverzüglich
            erstatten.
          </li>
        </ol>
        <h6 id="m3207">Abonnements</h6>
        <ol type="a">
          <li>
            Als "Abonnement" wird der regelmäßige Bezug von Produkten oder
            anderen Leistungen durch "Abonnenten" (wie Kunden im Rahmen von
            Abonnementverträgen bezeichnet werden) im Rahmen einer laufenden
            Vertragsbeziehung (auch bezeichnet als "Abonnementvertrag") über
            einen festgelegten Zeitraum hinweg (auch bezeichnet als
            "Bezugszeitraum") verstanden .
          </li>
          <li>
            Ein Abonnementvertrag verpflichtet den Verkäufer zur Lieferung der
            von dem Abonnementvertrag umfassten Leistungen oder Vornahme
            sonstiger Handlungen zu den vereinbarten Zeitpunkten oder
            Intervallen und innerhalb des vereinbarten Bezugszeitraums. Die
            Details zu den einzelnen Abonnements werden jeweils bei deren
            jeweiligen Angeboten angegeben.
          </li>
          <li>
            Die Kündigung erfolgt zum nächsten Leistungs- oder Liefertermin oder
            der nächsten Lieferung innerhalb des Bezugszeitraums.
          </li>
          <li>
            Abonnements werden jeweils zu Beginn des Bezugszeitraums im Voraus
            abgerechnet.
          </li>
          <li>
            Der Abonnent teilt dem Verkäufer Änderungen der Liefer- oder
            Zustelladresse unverzüglich mit und ist andernfalls für
            Lieferhindernisse aufgrund der unterbliebenen Mitteilung
            verantwortlich.
          </li>
          <li>
            Das Recht zu einer außerordentlichen Kündigung des
            Abonnementvertrages bleibt entsprechend den gesetzlichen Vorgaben
            vorbehalten.
          </li>
          <li>
            Abonnementverträge können in Textform gekündigt werden (z.B.
            E-Mail).
          </li>
        </ol>
        <h6 id="m3117">8. Gewährleistung und Haftung</h6>
        <ol type="a">
          <li>
            Die Gewährleistung (Mängelhaftung) bestimmt sich vorbehaltlich
            folgender Regelungen nach gesetzlichen Vorschriften.
          </li>
          <li>
            Der Verkäufer trägt keine Gewähr für die Internetverbindung des
            Kunden, die vom Kunden eingesetzte Soft- und Hardware sowie etwaige
            durch sie verursachten Störungen der Vertragsbegründung oder
            -durchführung zwischen dem Kunden und Verkäufer.
          </li>
          <li>
            Der Verkäufer haftet auf Schadensersatz unbeschränkt, soweit die
            Schadensursache auf Vorsatz oder grober Fahrlässigkeit beruht.
            Ferner haftet der Verkäufer für die leicht fahrlässige Verletzung
            von wesentlichen Pflichten, deren Verletzung die Erreichung des
            Vertragszwecks gefährdet, für die Verletzung von Pflichten, deren
            Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt
            erst ermöglicht und auf deren Einhaltung der Kunde regelmäßig
            vertraut (Kardinalpflichten) oder im Fall vereinbarter
            Garantieversprechen. In diesem Fall haftet der Verkäufer jedoch nur
            für den vorhersehbaren, vertragstypischen und erwartbaren Schaden.
            Der Verkäufer haftet nicht für die leicht fahrlässige Verletzung
            anderer als der vorstehend genannten Pflichten. Die vorstehenden
            Haftungsbeschränkungen gelten nicht bei Verletzung von Leben, Körper
            und Gesundheit, für einen Mangel nach Übernahme einer Garantie für
            die Beschaffenheit des Produktes und bei arglistig verschwiegenen
            Mängeln. Die Haftung nach dem Produkthaftungsgesetz bleibt
            unberührt. Soweit die Haftung des Verkäufers ausgeschlossen oder
            beschränkt ist, gilt dies auch für die persönliche Haftung von
            Arbeitnehmern, Vertretern und Erfüllungsgehilfen. Im Übrigen sind
            Schadensersatzansprüche des Kunden ausgeschlossen. Die vorstehenden
            Haftungsregelungen gelten auch für Schadensersatzansprüche des
            Kunden im Rahmen der gesetzlichen Gewährleistung des Verkäufers.
          </li>
        </ol>
        <h6 id="m2981">9. Schlussbestimmungen</h6>
        <p>
          Der Gerichtstand befindet sich am Sitz des Verkäufers, wenn der Kunde
          Kaufmann, juristische Person des öffentlichen Rechts oder
          öffentlich-rechtliches Sondervermögen ist oder der Kunde im Sitzland
          des Verkäufers keinen allgemeinen Gerichtsstand hat. Das Recht des
          Verkäufers einen anderen zulässigen Gerichtsstand zu wählen, bleibt
          vorbehalten.
        </p>
        <h6 id="m3093">10. Streitschlichtung und Verbraucherstreitbeilegung</h6>
        <ol type="a">
          <li>
            Die Europäische Kommission stellt eine Plattform zur
            Online-Streitbeilegung (OS) bereit, die Sie unter
            <a href="https://ec.europa.eu/consumers/odr/" target="_blank"
              >https://ec.europa.eu/consumers/odr/</a
            >
            finden. Verbraucher haben die Möglichkeit, diese Plattform für die
            Beilegung ihrer Streitigkeiten zu nutzen.
          </li>
          <li>
            Wir sind nicht bereit und nicht verpflichtet an einem
            Streitbeilegungsverfahren vor einer
            Verbraucherstreitschlichtungsstelle teilzunehmen.
          </li>
        </ol>
        <p class="seal">
          <a
            href="https://datenschutz-generator.de/?l=de"
            title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
            target="_blank"
            rel="noopener noreferrer nofollow"
            >Erstellt mit dem Datenschutz-Generator.de von Dr. Thomas
            Schwenke</a
          >
        </p>
      </article>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  data() {
    return {};
  },
};
</script>

<style scoped></style>
