<template>
  <div class="rounded product-info" v-if="selectedProduct.name">
    <div class="overlay shadow-sm"></div>
    <div class="plan-title">{{ selectedProduct.name }} Plan</div>
    <div class="price-tag">
      <div v-if="selectedProduct.trialPeriodDays" class="text-muted">
        {{ selectedProduct.trialPeriodDays }}
        {{ $t(`productInfo.trialPeriod`) }}
      </div>
      <div>
        {{ selectedProduct.price }}
        {{ $t(`productInfo.${selectedProduct.currency}`) }} /
        {{ $t(`productInfo.${selectedProduct.period}`) }}
        <small>{{
          $t(
            `productInfo.${
              selectedProduct.isExclusiveVAT ? "exclusive" : "inclusive"
            }`
          )
        }}</small>
      </div>

      <small class="text-muted">
        {{ $t(`productInfo.${selectedProduct.cancellationPeriod}`) }}</small
      >
    </div>
    <div class="font-weight-bold">{{ $t(`productInfo.includedFeatures`) }}</div>
    <div
      v-for="feature in selectedProduct.features"
      :key="feature"
      class="feature-tag"
    >
      <img src="/checkmark.svg" />
      <span> {{ feature }} </span>
    </div>
    <p class="legal-notice">{{ $t("productInfo.legalB2BNotice") }}</p>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    selectedProduct: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    info() {
      if (this.selectedProduct.productWebsiteJson) {
        return JSON.parse(this.selectedProduct.productWebsiteJson);
      } else {
        return [];
      }
    },
  },
};
</script>

<style scoped>
.product-info {
  width: 100%;
  padding: 1rem 2rem;
  background-color: rgba(200, 200, 200, 0.1);
}

.plan-title {
  text-transform: uppercase;
  font-weight: 600;
}
.price-tag {
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.feature-tag {
  text-align: left;
}

.legal-notice {
  font-size: 14px;
  margin-top: 2rem;
}
@media (min-width: 640px) {
  .product-info {
    width: 24vw;
    height: 400px;
    background-color: transparent;
    text-align: left;
  }
  .overlay {
    height: 100%;
    width: 40vw;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(200, 200, 200, 0.5);
  }
}
</style>
