<template>
  <div>
    <div v-for="prop in property.properties" :key="prop.title">
      <div v-if="!prop.disabled">
        <div v-if="prop.type === 'array'" class="my-4">
          <form-array
            :property="prop"
            :objectType="objectType"
            :selectOptions="selectOptions"
            :updateSelectCounter="updateSelectCounter"
            :formData="formValue[prop.title]"
            @update="
              formValue[prop.title] = $event;
              $emit('update', formValue);
            "
            @stateChange="
              isValid[prop.title] = $event;
              $emit('stateChange', isValid);
            "
          />
        </div>
        <form-field
          v-else
          :property="prop"
          :objectType="objectType"
          :selectOptions="selectOptions"
          :updateSelectCounter="updateSelectCounter"
          :formData="formValue[prop.title]"
          :required="property.required.includes(prop.title)"
          @update="
            formValue[prop.title] = $event;
            $emit('update', formValue);
          "
          @stateChange="
            isValid[prop.title] = $event;
            $emit('stateChange', isValid);
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormArray from "./FormArray.vue";
import FormField from "./FormField.vue";

export default {
  components: {
    FormField,
    FormArray,
  },
  props: {
    objectType: { type: String, default: "user" },
    selectFields: { type: Array, default: null },
    property: { type: Object, default: null },
    formData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    selectOptions: { type: Object, default: null },
    updateSelectCounter: { type: Number, default: 0 },
  },
  data() {
    return {
      formValue: {},
      isValid: {},
    };
  },
  computed: {},
  mounted() {
    this.formValue = this.formData;

    const values = Object.values(this.property.properties);
    values.forEach((prop) => {
      if (prop.type === "array") {
        this.formValue[prop.title] = [];
      }
    });
    this.$emit("update", this.formValue);
  },
  watch: {
    formData: function (newValue) {
      this.formValue = newValue;
    },
  },
};
</script>
